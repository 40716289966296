// @flow
import { createSlice } from "@reduxjs/toolkit";

const GeneralReducer = createSlice({
  name: "general",
  initialState: {
    media: [],
    models: [],
  },
  reducers: {
    // UPLOAD IMAGE
    uploadImageRequest() {},

    // UPLOAD AUDIO
    uploadAudioRequest() {},

    // GET MEDIA LISTING
    getMediaListingRequest() {},
    getMediaListingSuccess(state, { payload }) {
      state.media = payload;
    },

    // GET MEDIA STATUS
    getMediaStatusRequest() {},

    // DELETE UPLOADED MEDIA
    deleteUploadMediaRequest() {},

    // GENERATE UPLOAD URLZ
    generateUploadURLRequest() {},

    // UPLOAD MEDIA
    uploadMediaRequest() {},

    // GENERATE DOWNLOAD URL
    generateDownloadURLRequest() {},

    // DELETE MEDIA
    deleteMediaRequest() {},

    // GET MODELS
    getModelsRequest() {},
    getModelsRequestSuccess(state, { payload }) {
      state.models = payload;
    },
  },
});

export const {
  uploadImageRequest,
  uploadAudioRequest,
  getMediaListingRequest,
  getMediaListingSuccess,
  generateUploadURLRequest,
  uploadMediaRequest,
  generateDownloadURLRequest,
  deleteMediaRequest,
  getMediaStatusRequest,
  deleteUploadMediaRequest,
  getModelsRequest,
  getModelsRequestSuccess,
} = GeneralReducer.actions;

export default GeneralReducer.reducer;
