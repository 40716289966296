import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../components";
import { CHAT_ROUTE } from "../constants";
import { PublicSharedLayout } from "../shared-layout";
import {
  getUserAuthentication,
  navigateToLogin,
  setAccessToken,
} from "../utils";
import { CustomDispatch } from "../helpers";
import { getModelsRequest } from "../redux/slicers/general";

const PublicRoute = ({ children }) => {
  return <PublicSharedLayout>{children}</PublicSharedLayout>;
};

const PrivateRoute = ({ children }) => {
  const [getModels, modelsLoader] = CustomDispatch(getModelsRequest, true);

  // STATES
  const [isAuthenticated, setAuthenticated] = useState(false);

  // CONST VALS
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const devToken = searchParams.get("token");

  // CONST VALS
  // const navigate = useNavigate();
  // const isAuthenticated = getUserAuthentication();

  // HELPERS
  const getModelDataHelper = () => {
    getModels({
      success: () => {
        const redirectUrl = localStorage.getItem("redirectUrl");
        if (redirectUrl) {
          navigate(window.location.pathname + redirectUrl);
          localStorage.removeItem("redirectUrl");
        }
      },
    });
  };

  // HANDLERS
  const checkAuthHandler = () => {
    const isAuthenticated = getUserAuthentication();
    setAuthenticated(isAuthenticated);
    if (!isAuthenticated) return navigateToLogin();
    getModelDataHelper();
  };

  // HOOKS
  useEffect(() => {
    async function setAccessTokenHandler() {
      if (!devToken) return checkAuthHandler();

      await setAccessToken(devToken);
      checkAuthHandler();
    }
    setAccessTokenHandler();
  }, []);

  if (!isAuthenticated || modelsLoader) return <Loader />;
  return children;
};

const AuthRoute = ({ children }) => {
  // CONST VALS
  const navigate = useNavigate();
  const isAuthenticated = getUserAuthentication();

  // HOOKS
  useEffect(() => {
    if (isAuthenticated) {
      navigate(CHAT_ROUTE);
    }
  }, [isAuthenticated, navigate]);

  return children;
};

export { PublicRoute, PrivateRoute, AuthRoute };
