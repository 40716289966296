import React from "react";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import "./styles.scss";

const Button = ({
  children,
  onClick,
  disabled,
  isLoading,
  className = "",
  isLink = false,
  link = "",
  icon,
  loaderColor = "#fff",
  invertedTheme = false,
  bordered = false,
  boldText = false,
  ...rest
}) => {
  const linkSec = () => (
    <>
      {isLink && !disabled ? (
        <Link
          to={link}
          className={`custom-btn ${invertedTheme ? "secondary" : ""} ${
            bordered ? "bordered" : ""
          } ${boldText ? "bold" : ""} ${className}`}
          {...rest}>
          {innerSec()}
        </Link>
      ) : (
        <button
          type="submit"
          disabled={disabled || isLoading}
          onClick={onClick}
          className={`custom-btn ${invertedTheme ? "secondary" : ""} ${
            bordered ? "bordered" : ""
          } ${boldText ? "bold" : ""} ${className}`}
          {...rest}>
          {innerSec()}
        </button>
      )}
    </>
  );
  const innerSec = () => (
    <>
      {isLoading ? (
        <BeatLoader size={8} color={loaderColor} />
      ) : (
        <>
          {icon ? icon : null}
          {children}
        </>
      )}
    </>
  );
  return linkSec();
};

export default Button;
