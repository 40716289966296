const APP_CONFIG = {
  default: {
    id: "asugpt",
    isDefault: true,
    title: "ASU Gpt",
    desclaimerMessage: "ASU GPT may display incorrect or false information.",
    inputBoxPlaceholder: "Type a prompt",
    collection: "user",
    top_k: 3,
    enableSearch: true,
    modelselector: true,
    uploadfiles: true,
    model: "random",
    restrictDataOption: true,
    restrictDataLabel: "Restrict to only ASU data",
    splashScreenTitle: "Ask ASU GPT any questions you may have about ASU!",
  },
  healthplan: {
    id: "healthplan",
    title: "ASU Health Research Plan Bot",
    description:
      // eslint-disable-next-line max-len
      "The bot references the ASU Health Research Plan document (January 2024 Edition) and will do its best to answer questions only based on this document.",
    desclaimerMessage: "The bot can make mistakes. Please verify responses.",
    splashScreenTitle:
      "Ask the ASU Health Research Plan Bot any questions you have about research at ASU!",
    inputBoxPlaceholder: "Ask a question",
    collection: "healthplan",
    top_k: 50,
    enableSearch: true,
    modelselector: false,
    uploadfiles: false,
    model: "gpt4o",
    restrictDataOption: false,
    botname: "ASU Health Research Plan Bot",
  },
  syllabus: {
    id: "syllabus",
    title: "Ask Your Syllabus Bot",
    description:
      "The bot is trained on two sample Eng 101 and Eng 102 syllabus.",
    desclaimerMessage:
      "Please use caution as the bot may give incorrect or false information.",
    splashScreenTitle:
      "Ask Your Syllabus Bot can answer any questions you have about your class syllabus!",
    inputBoxPlaceholder: "Ask a question",
    collection: "syllabus",
    top_k: 10,
    enableSearch: true,
    modelselector: false,
    uploadfiles: false,
    model: "gpt4o",
    restrictDataOption: false,
    botname: "Ask Your Syllabus Bot",
  },
  financialaid: {
    id: "financialaid",
    title: "Financial Aid Bot",
    description:
      "The bot is trained on the Financial Aid Rush Document provided on Dec 18, 2023.",
    desclaimerMessage:
      "Please use caution as the bot may give incorrect or false information.",
    splashScreenTitle:
      "The Financial Aid Bot can answer any questions you may have about financial aid!",
    inputBoxPlaceholder: "Ask a question",
    collection: "financialaidrush",
    top_k: 20,
    enableSearch: true,
    modelselector: false,
    uploadfiles: false,
    model: "gpt4o",
    restrictDataOption: false,
    botname: "Financial Aid Bot",
  },
};
export default APP_CONFIG;
