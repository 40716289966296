import {React} from "react";
import "./styles.scss";
import { Loader } from "../../../../../components";

const Avatar = ({ isAvatarLoading }) => {
  return (
    <div className="blur-wrapper">
      {isAvatarLoading && (
        <div className="loader-class">
          <Loader />
        </div>
      )}

      <div className="video-container">
          <video
            id="my-video-element-id"
            className="video-player"
            autoPlay
            playsInline
          >
            <track default kind="captions" srclang="en" src="SUBTITLE_PATH" />
          </video>
          <audio id="my-audio-element-id" autoPlay>
            <track default kind="captions" srclang="en" src="SUBTITLE_PATH" />
          </audio>
        </div>
      </div>
  );
};


export default Avatar;