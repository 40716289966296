import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ACCESS_TYPES, CHAT_ROUTE, ERROR_ROUTE, PROJECT_CHAT_ROUTE } from "../constants";
import { Helmet } from 'react-helmet'
import {
  PrivateRoute,
  PublicRoute,
  AuthRoute,
} from "../config/routes-handling";
import { Error, Chat } from "../modules";
import { getAppConfig } from "../utils";

const renderRouteSharedLayout = (
  title,
  description,
  access,
  component,
  appTitle
) => (
  <>
    <Helmet>
      <title>
        {title ? `${title} |` : ''} {appTitle}
      </title>
      {!!description && <meta name="description" content={description} />}
    </Helmet>
    {access === ACCESS_TYPES.AUTH ? (
      <AuthRoute> {component}</AuthRoute>
    ) : access === ACCESS_TYPES.PRIVATE ? (
      <PrivateRoute>{component}</PrivateRoute>
    ) : (
      <PublicRoute>{component}</PublicRoute>
    )}
  </>
)

const PageRoutes = () => {
  // CONST VALS
  const location = useLocation();
  const appConfig = getAppConfig();

  const ROUTES = [
    // PUBLIC ROUTES

    // AUTH ROUTES

    // PRIVATE ROUTE
    {
      route: CHAT_ROUTE,
      title: "",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <Chat />,
    }, {
      route: PROJECT_CHAT_ROUTE,
      title: "",
      description: "",
      access: ACCESS_TYPES.PRIVATE,
      component: <Chat />,
    },
  ];

  // HOOKS
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {ROUTES.map((item, index) => (
        <Route
          path={item.route}
          element={renderRouteSharedLayout(
            item.title,
            item.description,
            item.access,
            item.component,
            appConfig.title
          )}
          key={index}
        />
      ))}
      <Route path={ERROR_ROUTE} element={<Error />} />
      <Route path="*" element={<Error />} />
    </Routes>
  )
};

export default PageRoutes;
