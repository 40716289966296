import React, { useEffect } from "react";
import { Form, Input, Rate } from "antd";
import { Button, Modal } from "../../../../../components";
import { inputFieldRule } from "../../../../../utils";
import "./styles.scss";

const FeedbackModal = ({
  preview,
  previewHandler,
  isReported,
  data,
  submitHandler,
}) => {
  // CONST VALS
  const [form] = Form.useForm();

  // HANDLERS
  const closeHandler = () => {
    form.resetFields();
    previewHandler();
  };
  const handleSubmit = ({ rating, reason }) => {
    closeHandler();
    const payload = {
      report: !isReported,
      rating: isReported ? 0 : rating,
      reason: isReported ? "" : reason,
    };
    setTimeout(() => {
      submitHandler(payload);
    }, 200);
  };

  // HOOKS
  useEffect(() => {
    if (data) form.setFieldsValue(data);
  }, [data, preview]);

  return (
    <Modal
      openHandler={closeHandler}
      width={600}
      open={preview}
      className="feedback-modal"
    >
      <div className="title-wrapper">
        <h2>Feedback</h2>
        <p>
          We are committed to enhancing the quality of our services and your
          insights play an integral role in this process. Please take a moment
          to share your thoughts about your experience.
        </p>
      </div>
      <Form
        form={form}
        disabled={isReported}
        className="feedback-form customize-form"
        onFinish={handleSubmit}
      >
        <h4>Your Overall Impressions</h4>
        <p>Could you please rate your overall experience?</p>
        <Form.Item
          name="rating"
          rules={inputFieldRule({
            name: "Rating",
            isRequired: !isReported,
            isWhiteSpace: false,
          })}
        >
          <Rate className="star-rating" disabled={isReported} />
        </Form.Item>
        <h4>Your feedback</h4>
        <Form.Item
          name="reason"
          rules={inputFieldRule({
            name: "Feedback",
            isRequired: false,
            requiredMessage: "Invalid feedback",
            isMax: true,
            max: 500,
          })}
        >
          <Input.TextArea />
        </Form.Item>
        <div className="bottom-wrapper">
          <Button type="button" className="back-btn" onClick={closeHandler}>
            Cancel
          </Button>
          <Button type="submit" invertedTheme className="submit-btn">
            {isReported ? "Remove feedback" : "Submit"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
