import React, { useEffect, useState, useRef } from "react";
import {
  convertBase64ToBlob,
  convertBlobToBase64,
  sliceBase64,
  toastAlert,
} from "../../../../../utils";
import { useSearchParams } from "react-router-dom";
import { ALERT_TYPES, MESSAGE_TYPE_JSON } from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faMicrophoneAlt,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import { WebSocketHandlers } from "../../../../../services/web-sockets";
import { sha1 } from "js-sha1";
import clsx from "clsx";
import { createChatRequest } from "../../../../../redux/slicers/chat";
import { CustomDispatch } from "../../../../../helpers";
import { useSelector } from "react-redux";
import { Images } from "../../../../../theme";
import "./styles.scss";

const AudioBox = ({
  previewHandler,
  isProjectApp,
  appConfig,
  projectId,
  selectedModel,
}) => {
  // STATES
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioPermission, setaudioPermission] = useState(false);
  const [cleanupFunction, setCleanupFunction] = useState(null);
  const [audioPlayer, setaudioPlayer] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [isProcessingPaused, setIsProcessingPaused] = useState(false);
  const [recordingAnalyzer, setRecordingAnalyzer] = useState(null);
  const [playingAnalyzer, setPlayingAnalyzer] = useState(null);

  // CUSTOM DISPATCH
  const [createChat] = CustomDispatch(createChatRequest);

  // REDUX DATA
  const projectData = useSelector(({ project }) => project.projectData);

  // REF TO KEEP TRACK OF AUDIO PLAYER
  const audioLoaderRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const isPaused = useRef(false);
  const isStopped = useRef(false);
  const recordingBarsRef = useRef([]);
  const playingBarsRef = useRef([]);
  const audioQueue = useRef([]);
  const responseStopped = useRef(false);

  // CONST VALS
  const discalimerMessage = appConfig?.desclaimerMessage;
  const [searchParams, setSearchParams] = useSearchParams();
  const { sendJsonMessage, lastMessage, getWebSocket } = WebSocketHandlers();
  const isUserSpeaking = audioRecorder?.state !== "inactive";
  const currentChat = searchParams.get("chat");
  const loaderClass = isProcessingPaused
    ? "hold"
    : isPlaying
    ? "speaking"
    : isLoading
    ? "loading"
    : isUserSpeaking
    ? "listening"
    : "";

  // HELPERS
  const analyseRoboResponse = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const analyzer = audioCtx.createAnalyser();
    analyzer.fftSize = 2048;
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const source = audioCtx.createMediaElementSource(audioPlayerRef.current);
    source.connect(analyzer);
    source.connect(audioCtx.destination);
    source.onended = () => {
      source.disconnect();
    };
    setPlayingAnalyzer({ analyzer, bufferLength, dataArray });
  };

  const uploadAudioHelper = (hashedBlob, audioBlob, session) => {
    let sendIndex = 0;
    responseStopped.current = false;
    const sendInterval = setInterval(() => {
      if (sendIndex >= audioBlob?.length) {
        clearInterval(sendInterval);
        return;
      }
      const payload = {
        action: "queryV2",
        query: "",
        enable_voice: true,
        session_id: session ?? currentChat,
        websocket_chunk: {
          total_chunks: audioBlob?.length,
          chunk: audioBlob[sendIndex],
          chunk_number: sendIndex + 1,
          payload_hash: hashedBlob,
        },
      };
      if (isProjectApp) {
        payload["project_id"] = projectId;
      } else {
        payload["model_provider"] = selectedModel.provider;
        payload["model_name"] = selectedModel.value;
      }
      if (MESSAGE_TYPE_JSON) {
        payload["response_format"] = { type: "json" };
      }
      sendIndex++;

      // send message to websocket
      if (sendIndex === audioBlob?.length - 1) {
        setTimeout(() => {
          sendJsonMessage(payload);
        }, 1000);
        return;
      }
      sendJsonMessage(payload);
    }, 100);
  };

  const createChatHelper = (hashedBlob, audioBlob) => {
    const chatName = `Audio chat - ${Math.floor(Math.random() * 100)}`;
    const payload = {
      method: "create",
      details: {
        app_id: isProjectApp ? projectId : appConfig.id,
        session_name: chatName,
      },
    };
    createChat({
      payload,
      success: (res) => {
        uploadAudioHelper(hashedBlob, audioBlob, res?.id);
        setSearchParams({ chat: res?.id });
      },
    });
  };

  // HANDLERS
  const closeHandler = () => {
    if (audioRecorder) audioRecorder.stop();

    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause(); // Stop playback
      audioPlayerRef.current.currentTime = 0; // Reset playback position
    }
    previewHandler();
  };

  const onAudioEndHandler = () => {
    if (audioQueue.current.length > 0) {
      const url = audioQueue.current.shift();
      if (audioPlayerRef.current) {
        audioPlayerRef.current.src = url;
        audioPlayerRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
          onAudioEndHandler();
        });
      }
      return;
    } else {
      if (!responseStopped.current) return;
      setaudioPlayer(null);
      isStopped.current = false;
      setPlaying(false);
      setLoading(false);
      if (audioRecorder && !isPaused.current) {
        if (audioRecorder.state === "recording") audioRecorder.stop();
        audioRecorder.start();
      }
    }
  };

  const setupMediaStream = async () => {
    try {
      let silenceTimer;
      const silenceThreshold = 1000; // Time in ms to detect silence
      const silenceDetectionThreshold = 10; // Threshold for detecting speaking
      const loudnessThreshold = 15; // Threshold for detecting loudness (adjust as needed)
      const mimeType = "audio/webm";

      let audioRecorder;
      let isRecording = false;
      let audioContext;
      let stream;

      if (!audioPermission) {
        // Request audio permission and stream
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();

        // Connect source to analyser
        source.connect(analyser);

        // Configure analyser
        analyser.fftSize = 2048;
        const bufferLength = analyser.fftSize;
        const dataArray = new Uint8Array(bufferLength);

        // set analyser data
        setRecordingAnalyzer({ analyser, bufferLength, dataArray });

        // Function to check for audio activity
        const checkAudioActivity = () => {
          if (!isProcessingPaused) {
            analyser.getByteTimeDomainData(dataArray);

            const isSpeaking = dataArray.some(
              (value) => Math.abs(value - 128) > silenceDetectionThreshold
            );
            if (isSpeaking) {
              onAudioActivity();
              if (
                !isRecording &&
                isVoiceLoud() &&
                !isPaused.current &&
                !isStopped.current
              ) {
                startRecording(stream);
              }
            }

            // Keep checking for activity
            animationFrameIdRef.current =
              requestAnimationFrame(checkAudioActivity);
          }
        };

        // Function to handle audio activity
        const onAudioActivity = () => {
          clearTimeout(silenceTimer);
          silenceTimer = setTimeout(() => {
            if (isRecording) {
              stopRecording();
            }
          }, silenceThreshold);
        };

        // Function to determine if the voice is loud
        const isVoiceLoud = () => {
          let sumSquares = 0;
          for (let i = 0; i < bufferLength; i++) {
            const value = dataArray[i] - 128;
            sumSquares += value * value;
          }
          const rms = Math.sqrt(sumSquares / bufferLength);

          return rms > loudnessThreshold;
        };

        // Function to start recording
        const startRecording = (stream) => {
          audioRecorder = new MediaRecorder(stream, { mimeType });
          audioRecorder.start();
          setAudioRecorder(audioRecorder);
          isRecording = true;
        };

        // Function to stop recording
        const stopRecording = () => {
          if (audioRecorder && audioRecorder.state !== "inactive") {
            audioRecorder.stop();
          }
          isRecording = false;
        };

        // Start checking for audio activity
        checkAudioActivity();

        // Set state or perform further actions
        setaudioPermission(true);

        // Set cleanup function
        setCleanupFunction(() => () => {
          if (stream) {
            stream.getTracks().forEach((track) => track.stop());
          }
          if (animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
          }
          if (silenceTimer) {
            clearTimeout(silenceTimer);
          }
          if (audioContext) {
            audioContext.close();
          }
        });
      }
    } catch (error) {
      console.error(error);
      toastAlert("Please provide access to your microphone", ALERT_TYPES.ERROR);
    }
  };

  const uploadAudioHandler = (event) => {
    if (typeof event.data === "undefined") return;
    const blob = new Blob([event.data], { type: "audio/mp3" });
    setLoading(true);
    isStopped.current = true;
    convertBlobToBase64(blob).then((base64) => {
      const hash = sha1(base64);
      const sliced = sliceBase64(base64);
      if (!currentChat) {
        createChatHelper(hash, sliced);
        return;
      }
      uploadAudioHelper(hash, sliced);
    });
  };

  const togglePause = () => {
    setPlaying(false);
    setLoading(false);
    setaudioPlayer(null);
    setIsProcessingPaused(!isPaused.current);
    if (isPaused.current) {
      if (audioRecorder && audioRecorder.state === "paused") {
        audioRecorder.resume();
      }
      if (audioRecorder && audioRecorder.state === "inactive") {
        audioRecorder.start();
      }
    } else {
      if (isStopped.current) isStopped.current = false;
      getWebSocket().close();
      audioQueue.current = [];
      if (audioRecorder && audioRecorder.state === "recording") {
        audioRecorder.stop();
      }
      if (audioPlayerRef.current) {
        audioPlayerRef.current.pause();
        audioPlayerRef.current.currentTime = 0;
      }
    }
    isPaused.current = !isPaused.current;
  };

  // HOOKS
  useEffect(() => {
    async function setup() {
      if (!audioPermission && !cleanupFunction) await setupMediaStream();
    }
    setup();
    return () => {
      if (cleanupFunction) {
        cleanupFunction();
      }
    };
  }, [audioPermission, cleanupFunction]);

  useEffect(() => {
    if (audioRecorder)
      audioRecorder.addEventListener("dataavailable", uploadAudioHandler);

    return () => {
      if (audioRecorder)
        audioRecorder.removeEventListener("dataavailable", uploadAudioHandler);
    };
  }, [audioRecorder]);

  useEffect(() => {
    if (audioPlayer) audioPlayer.addEventListener("ended", onAudioEndHandler);

    return () => {
      if (audioPlayer)
        audioPlayer.removeEventListener("ended", onAudioEndHandler);
    };
  }, [audioPlayer]);

  useEffect(() => {
    if (recordingAnalyzer === null) return;
    if (audioRecorder?.state === "inactive") return;

    const { analyser, dataArray } = recordingAnalyzer;

    const updateBars = () => {
      analyser.getByteFrequencyData(dataArray);
      const sum = dataArray.reduce((a, b) => a + b, 0);
      const avgFrequency = sum / dataArray.length;
      const opacity = Math.min(avgFrequency / 80, 1);
      recordingBarsRef.current.forEach((bar, i) => {
        if (bar) {
          const barHeight = (dataArray[i] / 400) * 100; // Convert to percentage
          bar.style.height = `${barHeight}%`;
        }
      });
      if (audioLoaderRef.current)
        audioLoaderRef.current.style.opacity = opacity;
      requestAnimationFrame(updateBars);
    };

    updateBars();
  }, [recordingAnalyzer]);

  useEffect(() => {
    if (!isPlaying) return;
    if (!playingAnalyzer || !audioPlayer) return;
    const { analyzer, dataArray } = playingAnalyzer;

    const updateBars = () => {
      analyzer.getByteFrequencyData(dataArray);
      playingBarsRef.current.forEach((bar, i) => {
        if (bar && i !== playingBarsRef.current.length - 1) {
          const barHeight = (dataArray[i] / 300) * 100;
          bar.style.height = `${barHeight}%`;
        }
      });
      requestAnimationFrame(updateBars);
    };

    updateBars();
  }, [playingAnalyzer]);

  useEffect(() => {
    if (!lastMessage) return;

    const data = JSON.parse(lastMessage.data);
    const message = data?.response;
    const audio_message = data?.audio_response;

    if ("message" in data) return;

    if ("error" in data) {
      if (data.error?.includes(`<EOS>`)) {
        responseStopped.current = true;
        onAudioEndHandler();
        return;
      }
      toastAlert(data.error, ALERT_TYPES.ERROR);
      return;
    }

    if (message?.includes(`<EOS>`)) {
      responseStopped.current = true;
      return;
    }

    if (!audio_message) return;

    const blob = convertBase64ToBlob(audio_message);
    if (!blob) {
      console.error("Failed to convert Base64 to Blob");
      return;
    }
    const url = URL.createObjectURL(blob);

    // If no audio is playing, initialize the player
    if (!audioPlayer) {
      setLoading(false);
      const tmp = new Audio(url);
      setaudioPlayer(tmp);
      audioPlayerRef.current = tmp;
      setPlaying(true);
      analyseRoboResponse();
      tmp.play().catch((error) => {
        onAudioEndHandler();
        console.error("Error playing audio:", error);
      });
    } else {
      if (audioPlayer.paused) {
        audioPlayer.src = url;
        audioPlayer.play().catch((error) => {
          onAudioEndHandler();
          console.error("Error playing audio:", error);
        });
        return;
      }
      audioQueue.current.push(url);
    }
  }, [lastMessage]);

  return (
    <div className="audio-box">
      <div className="top-box">
        <div ref={audioLoaderRef} className={clsx("audio-loader", loaderClass)}>
          <div className="tips">
            {[...Array(6)].map((_, i) => (
              <span key={i} ref={(el) => (playingBarsRef.current[i] = el)} />
            ))}
            <img
              src={Images.VoiceLoader}
              className="voice-loader"
              alt="loader"
            />
          </div>
          <span className="listening-badge" />
        </div>
        <div className="action-box">
          <button className="pause" onClick={togglePause}>
            <FontAwesomeIcon icon={isPaused.current ? faPlay : faPause} />
          </button>
          <button className="cancel" onClick={closeHandler}>
            <FontAwesomeIcon icon={faStop} />
          </button>
        </div>
      </div>
      <div className="bottom-box">
        <div className={clsx("listening-box", isUserSpeaking && "active")}>
          <FontAwesomeIcon icon={faMicrophoneAlt} />
          {!!isUserSpeaking && (
            <div className="frequency">
              {Array.from({ length: 5 }).map((_, i) => (
                <div
                  key={i}
                  className="bar"
                  ref={(el) => (recordingBarsRef.current[i] = el)}
                 />
              ))}
            </div>
          )}
        </div>
        <p className="tagline">
          {isProjectApp
            ? projectData?.desclaimerMessage ?? discalimerMessage
            : discalimerMessage}
        </p>
      </div>
    </div>
  );
};

export default AudioBox;
