import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { callRequest, CHAT, FEEDBACK } from '../../config/web-service';
import { ALERT_TYPES, TOAST_MESSAGES } from '../../constants';
import { toastAlert } from '../../utils';
import {
  createChatRequest,
  createChatSuccess,
  deleteChatRequest,
  deleteChatSuccess,
  getChatsRequest,
  getChatsSuccess,
  getMessagesRequest,
  getMessagesSuccess,
  updateChatRequest,
  updateChatSuccess,
  sendFeedbackRequest,
  deleteFeedbackRequest,
  clearMessages
} from '../slicers/chat';
import {
  manipulateChatDetail,
  manipulateChatList
} from '../../data-manipulator/chat';

// CREATE CHAT
function* createChat() {
  while (true) {
    const {
      payload: { payload, responseCallback }
    } = yield take(createChatRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CHAT,
        payload
      });
      if (response.status) {
        const chatdata = manipulateChatDetail(
          response?.data?.chat_session,
          true
        );
        yield put(createChatSuccess(chatdata));
        responseCallback?.(true, chatdata);
      } else {
        responseCallback?.(false, response.data);
        toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GET CHATS LISTING
function* getChatsListing(action) {
  const {
    payload: { payload, responseCallback }
  } = action;
  try {
    const response = yield call(callRequest, {
      url: CHAT,
      payload
    });
    if (response.status) {
      const data = {
        chats: manipulateChatList(response?.data?.chat_sessions),
        isMoreData: payload?.next_page
      };
      yield put(getChatsSuccess(data));
    }
    responseCallback?.(response?.status, response?.data);
    !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// GET CHAT MESSAGES
function* getChatMessages(action) {
  const {
    payload: { payload, model, responseCallback }
  } = action;

  try {
    const response = yield call(callRequest, {
      url: CHAT,
      payload
    });
    const models = yield select((state) => state.general.models);

    if (response.status) {
      const data = {
        messages: manipulateChatDetail(
          response?.data?.chat_session,
          false,
          model,
          models
        ),
        isMoreData: payload?.next_page,
        nextPageData: response?.data?.next_page,
        storeData: payload?.storeData ?? true
      };
      yield put(getMessagesSuccess(data));
      responseCallback?.(true, data);
    } else {
      yield put(clearMessages());
      responseCallback?.(response.status, response?.data);
      toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// UPDATE CHAT
function* updateChat() {
  while (true) {
    const {
      payload: { payload, responseCallback }
    } = yield take(updateChatRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CHAT,
        payload
      });
      if (response.status) {
        const chatdata = manipulateChatDetail(response?.data);
        yield put(updateChatSuccess(chatdata));
      }
      responseCallback?.(response?.status, response.data);
      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// DELETE CHAT
function* deleteChat() {
  while (true) {
    const {
      payload: { payload, responseCallback }
    } = yield take(deleteChatRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CHAT,
        payload
      });
      if (response.status) {
        yield put(deleteChatSuccess(payload.details.session_id));
      }
      const toastMessage = response?.status
        ? TOAST_MESSAGES.CHAT_DELETE_SUCCESS
        : response?.message;
      responseCallback?.(response?.status, response);
      toastAlert(
        toastMessage,
        response?.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// SEND FEEDBACK
function* sendFeedback() {
  while (true) {
    const {
      payload: { payload, responseCallback }
    } = yield take(sendFeedbackRequest.type);
    try {
      const response = yield call(callRequest, {
        url: FEEDBACK,
        payload
      });
      responseCallback?.(response.status, response.data);
      if (!response.status) {
        toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// DELETE FEEDBACK
function* deleteFeedback() {
  while (true) {
    const {
      payload: { payload, responseCallback }
    } = yield take(deleteFeedbackRequest.type);
    try {
      const response = yield call(callRequest, {
        url: FEEDBACK,
        payload
      });
      responseCallback?.(response.status, response.data);
      if (!response.status) {
        toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

export default function* root() {
  yield fork(createChat);
  yield takeEvery(getChatsRequest.type, getChatsListing);
  yield takeEvery(getMessagesRequest.type, getChatMessages);
  yield fork(updateChat);
  yield fork(deleteChat);
  yield fork(sendFeedback);
  yield fork(deleteFeedback);
}
